import React from 'react'
import BigCardList from '../bigCards/BigCardList'
import SideMenu from '../sideMenu/SideMenu'
import Smallcards from "../../components/smallcards/smallcards";
import AdsMidBottom from '../ads/AdsMidBottom';
import AdsMidBottom2 from '../ads/AdsMidBottom2';
import sidead1 from "../trendnews/sigortaladim-ad.jpg";
import sidead2 from "../trendnews/anadolu-hayat-ad.jpeg";
import sidead3 from "../trendnews/quick-ad.png";
import AyDergiCarousel from '../AkilliYasamDergiCarousel/AyDergiCarousel';
import TitleBar from '../titleBar/TitleBar';
import AyDergiCarouselMobile from '../AkilliYasamDergiCarousel/AyDergiCarouselMobile';
import AdsMidBottom3 from '../ads/AdsMidBottom3';


const CardsAndMenu = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-[18px] mt-4 pt-4 mb-6">
      <div className='col-span-2'>
      < AdsMidBottom /> <br />
      <div className="lg:hidden mobile-ads mb-2">
        <AdsMidBottom2 />
        <AdsMidBottom3 /> 
        </div>
        <Smallcards />
        <div className='pt-4'>
        <TitleBar title="Akıllı Yaşam Dergisi" /> <br />
        <div>
        <AyDergiCarousel />
        </div>
        <div className='ay-dergi-carousel-mobile hidden'>
        <AyDergiCarouselMobile />
        </div>
        </div>
        </div>
        <div className="hidden lg:block">
          <AdsMidBottom2 /> <br />
          <SideMenu />
        </div>
      </div>
  )
}

export default CardsAndMenu