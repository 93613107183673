import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./parite.css";

const currencyPairs = [
    { base: 'TRY', quote: 'USD' },
    { base: 'EUR', quote: 'USD' },
    { base: 'JPY', quote: 'USD' },
    { base: 'GBP', quote: 'USD' },
    { base: 'RUB', quote: 'USD' },
    { base: 'EUR', quote: 'RUB' },
    { base: 'RUB', quote: 'JPY' },
    { base: 'USD', quote: 'CHF' },
    { base: 'AUD', quote: 'USD' },
    { base: 'NZD', quote: 'USD' }
];

function PariteOranlari() {
    const [exchangeRates, setExchangeRates] = useState({});
    const [parities, setParities] = useState([]);

    
    useEffect(() => {
        axios.get('https://finans.truncgil.com/today.json')
            .then(response => {
                const rates = { TRY: 1 }; // TRY değerini 1 olarak tanımla
                currencyPairs.forEach(pair => {
                    if (response.data[pair.base] && response.data[pair.quote]) {
                        rates[pair.base] = parseFloat(response.data[pair.base]['Satış'].replace(',', ''));
                        rates[pair.quote] = parseFloat(response.data[pair.quote]['Satış'].replace(',', ''));
                    }
                });
                setExchangeRates(rates);
                calculateParities(rates);
            })
            .catch(error => {
                console.error('API veri getirme hatası:', error);
            });
    }, []);

    const calculateParities = (rates) => {
        const calculatedParities = [];

        currencyPairs.forEach(pair => {
            if (rates[pair.base] && rates[pair.quote]) {
                const rate = rates[pair.quote] / rates[pair.base];
                calculatedParities.push({
                    baseCurrency: pair.base,
                    quoteCurrency: pair.quote,
                    rate: rate.toFixed(4)
                });
            }
        });

        setParities(calculatedParities); // Tüm pariteleri göster
    };

    return (
        <div className='parite mb-5'>
            <div className="container p-0">
                <h1 className="h2 pariteTitle">Parite Oranları</h1>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="th-style">#</th>
                                    <th className="text-center th-style">Parite Türü</th>
                                    <th className="text-center th-style">Oran</th>
                                </tr>
                            </thead>
                            <tbody>
                                {parities.length === 0 ? (
                                    <tr>
                                        <td colSpan="3" className="text-center">Yükleniyor...</td>
                                    </tr>
                                ) : (
                                    parities.map((parite, index) => (
                                        <tr key={index} className="hover-effect">
                                            <th scope="row">{index + 1}</th>
                                            <td className="text-center">
                                                {parite.baseCurrency}/{parite.quoteCurrency}
                                            </td>
                                            <td className="text-center">{parite.rate}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PariteOranlari;
