import React, { useEffect } from "react";
import "./home.css";
import Carousel from "../../components/carousel/Carousel";
import NewsWebsite from "../../components/actualNews/actualNews";
import MinCards from "../../components/minCards/minCards";
import CardsAndMenu from "../../components/cardsAndMenu/CardsAndMenu";
import Ref from "../../components/ref/Ref";
import AdsMidTop from "../../components/ads/AdsMidTop";
import AdsMidBottom from "../../components/ads/AdsMidBottom";
import CarouselSecond from "../../components/carouselSecond/carouselSecond";
import SgCarousel from "../../components/sgCarousel/SgCarousel";
import AdsDownPopup from "../../components/ads/adsDownPopup";
import AdsSquares from "../../components/ads/AdsSquares";
import AdsCenterPopup from "../../components/ads/adsCenterPopup"


const Home = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload(); 
    }, 120000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div >
      <div className="mx-[10px] md:mx-[80px] position-relative homepage-container-div">
      {/* <Carousel />
      <CarouselSecond /> */}
      <SgCarousel />
      <AdsSquares />
      <NewsWebsite />
      {/* <AdsSquares /> */}
      <AdsCenterPopup/>
      {/* <NewsWebsite /> */}
      {/* <AdsMidBottom /> */}
      <CardsAndMenu />
      <AdsMidTop />
      {/* <MinCards /> */}
      <Ref/></div>
      <AdsDownPopup />
    </div>
  );
};

export default Home;
