import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./adsmid.css";

const AdContainer = ({ ad }) => {
  const adRef = React.useRef(null);

  React.useEffect(() => {
    adRef.current.innerHTML = ''; // Önceki içeriği temizle

    if (ad.imageURL.includes('doubleclick.net') || ad.imageURL.includes('sadbundle')) {
      const iframe = document.createElement('iframe');
      iframe.src = ad.imageURL;
      iframe.width = '300';
      iframe.height = '300';
      iframe.frameBorder = '0';
      iframe.scrolling = 'no';
      iframe.style = 'border: 0px; vertical-align: bottom;';
      if (ad.imageURL.includes('sadbundle')) {
        iframe.allowFullscreen = true;
      }
      adRef.current.appendChild(iframe);
    } else {
      const img = document.createElement('img');
      img.src = ad.imageURL;
      img.alt = ad.title;
      img.style = 'width: 100%; height: 100%;';
      adRef.current.appendChild(img);
    }
  }, [ad]);

  return (
    <a href={ad.link} target="_blank" rel="noopener noreferrer">
      <div className="advertisement" style={{ width: "300px", height: "300px" }} ref={adRef}>
        {/* Reklam içeriği buraya dinamik olarak eklenecek */}
      </div>
    </a>
  );
};

const AdsSquares = () => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get('https://api.sigortagundem.com/api/ads');
        const data = response.data;
        
        const filteredAds = data.filter(ad => 
          (ad.title.includes("Manşet Altı KARE Banner - SOL") || 
          ad.title.includes("Manşet Altı KARE Banner - ORTA") || 
          ad.title.includes("Manşet Altı KARE Banner - SAĞ")) &&
          ad.isActive === true
        );

        setAds(filteredAds);

        // Hit sayısını artırmak için her reklam için API çağrısı yapalım
        for (const ad of filteredAds) {
          await axios.post(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`);
        }
      } catch (error) {
        console.error("Reklamlar çekilemedi:", error);
      }
    };

    fetchAds();
  }, []);

  return (
    <div>
      <div className='mid-top-ads-container2'>
        {ads.map((ad, index) => (
          <AdContainer key={index} ad={ad} />
        ))}
      </div>
    </div>
  );
};

export default AdsSquares;