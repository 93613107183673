import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";

// SignUpForm component
const SignUpForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    if (password.length < 8) {
      setError("Şifreniz en az 8 karakter olmalıdır.");
      setIsLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      setError("Şifreler eşleşmiyor.");
      setIsLoading(false);
      return;
    }
    if (!acceptedTerms) {
      setError("Kullanım sözleşmesini kabul etmelisiniz.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `https://api.sigortagundem.com/api/auth/register`,
        {
          name: `${firstName} ${lastName}`,
          username,
          email,
          password, // Şifre doğrudan gönderiliyor, hashlenmiyor
        }
      );

      if (response.status === 200 && response.data.message === "Kullanıcı başarıyla kaydedildi.") {
        alert("Kayıt işlemi başarılı!");

        // Modal'ı kapat
        const modal = document.getElementById("login-modal");
        if (modal && typeof window !== "undefined" && window.bootstrap) {
          const bootstrapModal = window.bootstrap.Modal.getInstance(modal);
          if (bootstrapModal) {
            bootstrapModal.hide();
          }
        }

        // Form alanlarını temizle
        setFirstName("");
        setLastName("");
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setAcceptedTerms(false);

        // Sayfayı yenile
        window.location.reload();
      } else {
        setError(response.data.message || "Kayıt işlemi başarısız oldu.");
      }
    } catch (error) {
      console.error("Signup error:", error);
      setError("Kayıt sırasında bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSignUp} className="leading-8">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="first-name">Adınız</label>
          <br />
          <input
            type="text"
            id="first-name"
            name="first-name"
            className="login-modal-input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="last-name">Soyadınız</label>
          <br />
          <input
            type="text"
            id="last-name"
            name="last-name"
            className="login-modal-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
      </div>

      <label htmlFor="username">Kullanıcı Adınız</label>
      <br />
      <input
        type="text"
        id="username"
        name="username"
        className="login-modal-input"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
      <br />
      <label htmlFor="email">E-posta Adresiniz</label>
      <br />
      <input
        type="email"
        id="email"
        name="email"
        className="login-modal-input"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <br />
      <label htmlFor="password">Şifreniz</label>
      <br />
      <input
        type="password"
        id="password"
        name="password"
        className="login-modal-input pr-10"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <br />
      <label htmlFor="confirm-password">Şifreniz (Tekrar)</label>
      <br />
      <input
        type="password"
        id="confirm-password"
        name="confirm-password"
        className="login-modal-input"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
      <br />
      {error && <p style={{ color: "red" }}>{error}</p>}

      <div className="form-check flex items-center">
        <input
          className="form-check-input login-modal-check"
          type="checkbox"
          id="acceptTerms"
          checked={acceptedTerms}
          onChange={() => setAcceptedTerms(!acceptedTerms)}
        />
        &nbsp; &nbsp;
        <label className="form-check-label text-base" htmlFor="acceptTerms">
          <a href="" className="text-red-500 font-semibold">
            Kullanım sözleşmesini
          </a>{" "}
          okudum ve kabul ediyorum.
        </label>
      </div>

      <button
        className="login-modal-submit-btn"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? "Kaydediliyor..." : "Üye Kaydını Tamamla"}
      </button>
    </form>
  );
};

export default SignUpForm;
