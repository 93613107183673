import { Menu, X, Twitter, Instagram, Eye, EyeOff } from "lucide-react";
import { useState, useEffect } from "react";
import ekonomiLogo from "../../assets/ekonomi-manset-logo.png";
import SigortaGundemLogo from "../../assets/sigorta-gundem-logo.png";
import SgWhiteLogo from "../../assets/sigorta-gundem-white-logo.png";
import reklam1P from "../../assets/reklam.gif";
import "./navbar.css";
import React from "react";
import whiteLogo from "../../assets/sigorta-gundem-white-logo.png";
import WeatherWidget from "./WeatherWidget";
import CurrencyWidget from "./CurrencyWidget";
import { useLocation, NavLink } from "react-router-dom";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import AdContainer from "./AdContainer";
import { FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp, FaYoutube } from "react-icons/fa";
import Ad from "./Ad";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/");
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError("");
    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://api.sigortagundem.com/api/auth/login`,
        {
          login: loginUsername, // login olarak değiştirin
          password: loginPassword,
        }
      );

      if (response.status === 200 && response.data.token) {
        localStorage.setItem("token", response.data.token);
        setIsLoggedIn(true);
        alert("Giriş başarılı!");

        // Close the modal
        const modal = document.getElementById("login-modal");
        if (modal && typeof window !== "undefined" && window.bootstrap) {
          const bootstrapModal = window.bootstrap.Modal.getInstance(modal);
          if (bootstrapModal) {
            bootstrapModal.hide();
          }
        }

        // Clear form fields
        setLoginUsername("");
        setLoginPassword("");
      } else {
        setLoginError("Giriş başarısız. Lütfen bilgilerinizi kontrol edin.");
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoginError("Giriş sırasında bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    // e.preventDefault();
    // ... diğer kodlar aynı kalacak

    try {
      const response = await axios.post(
        `https://api.sigortagundem.com/api/auth/register`,
        {
          name: `${firstName} ${lastName}`,
          username,
          email,
          password,
        }
      );

      if (
        response.status === 200 &&
        response.data.message === "Kullanıcı başarıyla kaydedildi."
      ) {
        alert("Kayıt işlemi başarılı!");

        // Modal'ı güvenli bir şekilde kapat
        const modal = document.getElementById("login-modal");
        if (modal && typeof window !== "undefined" && window.bootstrap) {
          const bootstrapModal = window.bootstrap.Modal.getInstance(modal);
          if (bootstrapModal) {
            bootstrapModal.hide();
          }
        }

        // Form alanlarını temizle
        setFirstName("");
        setLastName("");
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setAcceptedTerms(false);

        // Sayfayı yenile
        window.location.reload();
      } else {
        setError(response.data.message || "Kayıt işlemi başarısız oldu.");
      }
    } catch (error) {
      // ... hata işleme kodu aynı kalacak
    } finally {
      setIsLoading(false);
    }
  };

  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const checkScroll = () => {
    if (!isScrolled && window.pageYOffset > 165) {
      setIsScrolled(true);
    } else if (isScrolled && window.pageYOffset <= 165) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [isScrolled]);
  const [livestreamLink, setLivestreamLink] = useState("");

  useEffect(() => {
    const fetchLivestreamLink = async () => {
      try {
        const response = await fetch(
          `https://213.239.216.181:3005/api/livestreams`
        );
        if (!response.ok) {
          throw new Error("Veri alınamadı.");
        }
        const data = await response.json();
        if (data.length > 0) {
          setLivestreamLink(data[0].link);
        } else {
          console.error("Canlı yayın verisi bulunamadı.");
        }
      } catch (error) {
        console.error(
          "Canlı yayın verisi alınırken bir hata oluştu:",
          error.message
        );
      }
    };

    fetchLivestreamLink();
  }, []);

  const navItems = [
    { label: "GÜNDEM", href: "/gundem" },
    { label: "DÜNYA", href: "/dunya" },
    { label: "KULİS", href: "/kulis" },
    { label: "KASKO & TRAFİK", href: "/kasko" },
    { label: "BES & HAYAT", href: "/bes" },
    { label: "ELEMENTER", href: "/elementer" },
    { label: "FOTO GALERİ", href: "/foto-galeri" },
  ];

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setError("Şifreniz en az 8 karakter olmalıdır.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Şifreler eşleşmiyor.");
      return;
    }
    if (!acceptedTerms) {
      setError("Kullanım sözleşmesini kabul etmelisiniz.");
      return;
    }

  
  };

  const [query, setQuery] = useState("");

  const submitSearch = async (event) => {
    event.preventDefault();
    setIsSearching(true);
    setSearchError("");

    try {
      // Instead of making the API call here, we'll navigate to the search page
      // The actual API call will be made in the SearchPage component
      navigate(`/search?q=${encodeURIComponent(query)}`);
    } catch (error) {
      console.error("Search error:", error);
      setSearchError("Arama sırasında bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    fetch("https://api.sigortagundem.com/api/ads")
      .then((response) => response.json())
      .then((data) => {
        const relevantAds = data.filter(
          (ad) =>
            (ad.title.includes("TEPE") || ad.title === "TEPE Yatay") &&
            ad.isActive === true
        );
  
        setAds(relevantAds);
      })
      .catch((error) => {
        console.error("Error fetching advertisement data:", error);
      });
  }, []);
  

  useEffect(() => {
    if (ads.length > 1) {
      const intervalId = setInterval(() => {
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [ads]);

  const handleAdClick = async (ad) => {
    if (ad && ad._id) {
      try {
        await fetch(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`, {
          method: "POST",
        });
        if (ad.link) {
          window.open(ad.link, "_blank", "noopener,noreferrer");
        }
      } catch (error) {
        console.error("Error incrementing hit count or redirecting:", error);
      }
    }
  };

  if (!ads) {
    return null; // Or a loading spinner
  }

  return (
    <div className="nvb">
      <ToastContainer />
      <div className="bg-white-300 hidden w-full md:flex flex-col items-center relative">
        <div className="center-content">
          <a href="/" className="logo">
            <img src={SigortaGundemLogo} alt="logo" className="" />
          </a>
          {/* <AdContainer ads={ads} currentAdIndex={currentAdIndex} handleAdClick={handleAdClick} /> */}
          {/* {ads.length > 0 && (
            <div
              className="advertisement"
              style={{ width: "728px", height: "90px" }}
            >
              {ads[currentAdIndex].imageURL.includes("sadbundle") ? (
                <iframe
                  src={ads[currentAdIndex].imageURL}
                  width="728"
                  height="90"
                  frameBorder="0"
                  scrolling="no"
                  allowFullScreen={true}
                />
              ) : (
                <img
                  src={ads[currentAdIndex].imageURL}
                  alt={ads[currentAdIndex].title}
                  onClick={() => handleAdClick(ads[currentAdIndex])}
                  style={{ cursor: "pointer", width: "100%", height: "100%" }}
                />
              )}
            </div>
          )} */}
          {ads.length > 0 && (
            <AdContainer
              ads={ads}
              currentAdIndex={currentAdIndex}
              handleAdClick={handleAdClick}
            />
          )}
        </div>
        {/* <p className="h-[1px] text-center text-2xl font-bold text-black bottom-0 w-full"></p> */}
      </div>

      <nav
        className={`navbar-link-container  flex justify-between items-center h-[55px] w-full md:px-52  text-white ${
          isScrolled ? "fixed shadow-md top-0 left-0 z-50 mb-14" : ""
        }`}
      >
        {/* Logo */}
        {/* <h1 className='w-full text-3xl font-bold text-[#00df9a]'>REACT.</h1> */}

        {/* Desktop Navigation */}
        <div className="flex h-full items-center">
          <div className="currency-widget-web flex justify-between items-center navbar-icon">
            <CurrencyWidget />
          </div>

          <ul className="hidden h-full md:flex items-center">
            <a
              href="/"
              className="navbar-link h-full flex items-center duration-200 navbar-home-button"
            >
              <span class="material-symbols-outlined">
home
</span>
            </a>
            {navItems.map((item, index) => (
              <li
                key={index}
                className={`navbar-link h-full flex items-center cursor-pointer duration-200 ${
                  location.pathname === item.href ? "active" : ""
                }`}
              >
                <div className=" lg:px-[20px]  rounded-xl">
                  <a href={item.href} target={item.target}>
                    {item.label}
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* Mobile Navigation Icon */}
        <div className="md:hidden  mx-4">
          <a href="/" className="logo">
            <img
              src={SgWhiteLogo}
              alt="psm-logo"
              className="mobile-logo mt-2"
            />
          </a>
        </div>
        <div className="block ml-6 flex items-center md:hidden">
          <button onClick={toggleNavbar}>
            {mobileDrawerOpen ? <X /> : <Menu />}
          </button>
        </div>
        {/* Mobile Navigation Menu */}
        <ul
          className={
            mobileDrawerOpen
              ? "fixed z-50 md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-400 bg-white ease-in-out duration-500"
              : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]"
          }
        >
          {/* Mobile Logo */}
          <div className=" my-4  md:hidden flex items-center justify-center">
            <a href="/" className="">
              <img
                src={SigortaGundemLogo}
                alt="psm-logo"
                className=" mobile-logo "
              />
            </a>
          </div>
          {/* Mobile Navigation Items */}
          {navItems.map((item, index) => (
            <li
              key={index}
              className="p-4 border-b text-gray-700 hover:bg-[#AE8D2F] font-semibold duration-300 hover:text-white cursor-pointer border-gray-600 mobile-menu-content"
            >
              <a href={item.href}>{item.label}</a>
            </li>
          ))}
          <div className="absolute text-gray-700 mt-4 text-3xl left-3 flex gap-3 mobile-menu-social-links ">
            <a
              href="https://twitter.com/sigortagundem"
              className="hover:text-[#50ABF1]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <FaTwitter />
              </div>
            </a>
            <a
              href="https://www.instagram.com/sigortagundem/"
              className="hover:text-[#FE1D80]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <FaInstagram />
              </div>
            </a>
            <a
              href="https://www.youtube.com/@psmdergisi9390"
              className="hover:text-[#FF0000]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <FaYoutube />
              </div>
            </a>
            <a
              href="https://www.linkedin.com/company/sigorta-g%C3%BCndem/"
              className="hover:text-[#0274B3]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <FaLinkedin />
              </div>
            </a>
            <a
              href="https://wa.me/905324627687"
              className="hover:text-[#26C943]"
              target="_blank"
              rel="noreferrer"
            >
              <div className=" grid grid-flow-col auto-cols-max items-center gap-3">
                <FaWhatsapp />
              </div>
            </a>
          </div>
        </ul>

        <div className="inline-flex h-[92%]">
          <div class="btn-group h-full">
            <button
              type="button"
              class="btn navbar-icon"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="material-symbols-outlined pt-1">
search
</span>
            </button>
            <div class="dropdown-menu dropdown-menu-end search-dropdown">
              <form onSubmit={submitSearch} className="d-flex items-center">
                <input
                  className="search-input md:w-[400px]"
                  placeholder="Ne aramak istersiniz?"
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <button
                  className="text-white bg-[#374140] p-2  hover:bg-[#AE8D2F]"
                  type="submit"
                  disabled={isSearching}
                >
                  {isSearching ? "Aranıyor..." : "Ara"}
                </button>
              </form>
            </div>
          </div>

          {isLoggedIn ? (
            <div className="dropdown">
              <button
                className="btn navbar-icon dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="material-symbols-outlined pt-1">
person
</span>
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <a className="dropdown-item" href="/profile">
                    Profil
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#" onClick={handleLogout}>
                    Çıkış Yap
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <button
              type="button"
              className="btn navbar-icon"
              data-bs-toggle="modal"
              data-bs-target="#login-modal"
            >
              <span class="material-symbols-outlined pt-1">
person
</span>
            </button>
          )}
          <div
            className="modal fade"
            id="login-modal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-fullscreen-md-down">
              <div className="modal-content border-0 rounded-md text-neutral-900 login-modal-content">
                <div className="modal-header text-left">
                  <ul className="nav nav-underline" id="myTab" role="tablist">
                    <li
                      className="nav-item login-modal-nav-item"
                      role="presentation"
                    >
                      <button
                        className="nav-link active"
                        id="login-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#login-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="login-tab-pane"
                        aria-selected="true"
                      >
                        Giriş Yap
                      </button>
                    </li>
                    <li
                      className="nav-item login-modal-nav-item"
                      role="presentation"
                    >
                      <button
                        className="nav-link"
                        id="sign-up-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#sign-up-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="sign-up-tab-pane"
                        aria-selected="false"
                      >
                        Kayıt Ol
                      </button>
                    </li>
                    <li
                      className="nav-item login-modal-nav-item"
                      role="presentation"
                    >
                      <button
                        className="nav-link"
                        id="password-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#password-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="password-tab-pane"
                        aria-selected="false"
                      >
                        Şifremi Unuttum
                      </button>
                    </li>
                  </ul>

                  <button
                    type="button"
                    className="btn-close login-modal-close-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="modal-body text-left px-12 login-modal-body">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="login-tab-pane"
                      role="tabpanel"
                      aria-labelledby="login-tab"
                      tabIndex="0"
                    >
                      <form className="leading-8" onSubmit={handleLogin}>
                        <label for="login-username">
                          Kullanıcı Adınız veya E-posta Adresiniz
                        </label>
                        <br />
                        <input
                          type="text"
                          id="login-username"
                          name="login-username"
                          className="login-modal-input"
                          value={loginUsername}
                          onChange={(e) => setLoginUsername(e.target.value)}
                          required
                        />
                        <br />
                        <label for="login-password">Şifreniz</label>
                        <br />
                        <div className="relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="login-password"
                            name="login-password"
                            className="login-modal-input pr-10"
                            value={loginPassword}
                            onChange={(e) => setLoginPassword(e.target.value)}
                            required
                          />
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <EyeOff size={20} />
                            ) : (
                              <Eye size={20} />
                            )}
                          </button>
                        </div>
                        <br />
                        {loginError && (
                          <p style={{ color: "red" }}>{loginError}</p>
                        )}
                        <div className="flex mt-2 justify-between items-center">
                          <div class="form-check flex items-center">
                            <input
                              class="form-check-input login-modal-check"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />{" "}
                            &nbsp; &nbsp;
                            <label
                              class="form-check-label text-base"
                              for="flexCheckDefault"
                            >
                              Beni Hatırla
                            </label>
                          </div>
                          <button
                            className="login-modal-submit-btn"
                            type="submit"
                            disabled={isLoading}
                          >
                            {isLoading ? "Giriş Yapılıyor..." : "Giriş Yap"}
                          </button>
                        </div>
                      </form>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="sign-up-tab-pane"
                      role="tabpanel"
                      aria-labelledby="sign-up-tab"
                      tabIndex="0"
                    >
                      {/* <form onSubmit={handleSignUp} className="leading-8">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label htmlFor="first-name">Adınız</label>
                            <br />
                            <input
                              type="text"
                              id="first-name"
                              name="first-name"
                              className="login-modal-input"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                          </div>
                          <div>
                            <label htmlFor="last-name">Soyadınız</label>
                            <br />
                            <input
                              type="text"
                              id="last-name"
                              name="last-name"
                              className="login-modal-input"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <label htmlFor="username">Kullanıcı Adınız</label>
                        <br />
                        <input
                          type="text"
                          id="username"
                          name="username"
                          className="login-modal-input"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        />
                        <br />
                        <label htmlFor="email">E-posta Adresiniz</label>
                        <br />
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="login-modal-input"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <br />
                        <label htmlFor="password">Şifreniz</label>
                        <br />
                        <input
                          type={"password"}
                          id="password"
                          name="password"
                          className="login-modal-input pr-10"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />

                        <br />
                        <label htmlFor="confirm-password">
                          Şifreniz (Tekrar)
                        </label>
                        <br />
                        <input
                          type="password"
                          id="confirm-password"
                          name="confirm-password"
                          className="login-modal-input"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                        <br />
                        {error && <p style={{ color: "red" }}>{error}</p>}

                        <div className="form-check flex items-center">
                          <input
                            className="form-check-input login-modal-check"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            checked={acceptedTerms}
                            onChange={() => setAcceptedTerms(!acceptedTerms)}
                          />
                          &nbsp; &nbsp;
                          <label
                            className="form-check-label text-base"
                            htmlFor="flexCheckDefault"
                          >
                            <a href="" className="text-red-500 font-semibold">
                              Kullanım sözleşmesini
                            </a>{" "}
                            okudum ve kabul ediyorum.
                          </label>
                        </div>

                        <input
                          className="login-modal-submit-btn"
                          type="submit"
                          value="Üye Kaydını Tamamla"
                        />
                      </form> */}
                      <SignUpForm />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="password-tab-pane"
                      role="tabpanel"
                      aria-labelledby="password-tab"
                      tabIndex="0"
                    >
                      <ForgotPasswordForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="navbar-weather-web flex items-center">
            <WeatherWidget />
          </div>
        </div>
      </nav>

      <div className="mobile-currency-weather hidden grid grid-cols-2 justify-between items-center">
        <CurrencyWidget />
        <WeatherWidget />
      </div>

      {/* Advertisement for mobile */}
      <div className="md:hidden mb-4 mt-[55px]">
      {ads.length > 0 && (
          <Ad
            ads={ads}
            currentAdIndex={currentAdIndex}
            handleAdClick={handleAdClick}
          />
        )}
      </div>

      <div className={`navbar.content ${isScrolled ? "h-16" : ""}`}></div>
    </div>
  );
};

export default Navbar;
