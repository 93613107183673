// AdsMidTop.jsx
import React, { useState, useEffect } from "react";
import "./adsmid.css";
import mbad2 from "./kaskolamag.jpeg"; // Default image or placeholder

const AdContainer = ({ ad, defaultImage, className }) => {
  const isIframe = ad && (ad.imageURL.includes("doubleclick.net") || ad.imageURL.includes("sadbundle"));

  if (!ad) return null;

  return (
    <a href={ad.link || "#"} target="_blank" rel="noopener noreferrer" className={`ad-container ${className}`}>
      {isIframe ? (
        <iframe
          src={ad.imageURL}
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          style={{ border: 0, verticalAlign: "bottom" }}
          allowFullScreen={ad.imageURL.includes("sadbundle")}
          title={ad.title || "Advertisement"}
        />
      ) : (
        <img
          src={ad.imageURL || defaultImage}
          alt={ad.title || "Advertisement"}
        />
      )}
    </a>
  );
};

const AdsMidTop = () => {
  const [adLeft, setAdLeft] = useState(null);
  const [adRight, setAdRight] = useState(null);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch("https://api.sigortagundem.com/api/ads");
        const data = await response.json();

        const leftAd = data.find(
          (ad) =>
            ad.title.includes("Dergiler Altında SOL KARE Banner") &&
            ad.isActive === true
        );

        const rightAd = data.find(
          (ad) =>
            ad.title.includes("Dergiler Altında Sol Kare Bannerın SAĞ YANI") &&
            ad.isActive === true
        );

        setAdLeft(leftAd);
        setAdRight(rightAd);

        // Increment hit count for both ads if they exist
        if (leftAd) {
          await fetch(`https://api.sigortagundem.com/api/ads/${leftAd._id}/hit`, {
            method: 'POST',
          });
        }
        if (rightAd) {
          await fetch(`https://api.sigortagundem.com/api/ads/${rightAd._id}/hit`, {
            method: 'POST',
          });
        }
      } catch (error) {
        console.error("Error fetching advertisement data:", error);
      }
    };

    fetchAds();
  }, []);

  return (
    <div className="mid-top-ads-container1">
      <AdContainer ad={adLeft} defaultImage={mbad2} className="ad-left" />
      <AdContainer ad={adRight} defaultImage={mbad2} className="ad-right" />
    </div>
  );
};

export default AdsMidTop;