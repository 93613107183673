import React, { useState, useEffect } from 'react';
import "./currencytable.css";
import altin from "./altin.png"

const CurrencyTable = () => {
  const flagUrls = {
    USD: 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-square-250.png',
    EUR: 'https://cdn.countryflags.com/thumbs/europe/flag-square-250.png',
    GBP: 'https://cdn.countryflags.com/thumbs/united-kingdom/flag-square-250.png',
    CHF: 'https://cdn.countryflags.com/thumbs/switzerland/flag-square-250.png',
    CAD: 'https://cdn.countryflags.com/thumbs/canada/flag-square-250.png',
    RUB: 'https://cdn.countryflags.com/thumbs/russia/flag-square-250.png',
    AED: 'https://cdn.countryflags.com/thumbs/united-arab-emirates/flag-square-250.png',
  };

  const [rates, setRates] = useState({});

  useEffect(() => {
    fetch('https://finans.truncgil.com/today.json')
      .then((response) => response.json())
      .then((data) => {
        setRates(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const getChangeClass = (change) => {
    if (!change) return '';
    return parseFloat(change.replace('%', '').replace(',', '.')) < 0 ? 'negative' : 'positive';
  };

  return (
    <div>
      
      <table className='w-full'>
        <thead className='currency-table-head'>
          <tr className='text-start '>
            <th></th>
            <th>Döviz/Altın</th>
            <th>Alış</th>
            <th>Değişim</th>
          </tr>
        </thead>
        <tbody className='currency-table-body'>
          {['USD', 'EUR', 'GBP', 'CHF', 'CAD', 'RUB', 'AED'].map((currency) => (
            <tr key={currency}>
              <td className='w-10'><img src={flagUrls[currency]} alt={`${currency} bayrağı`} /></td>
              <td>{currency}</td>
              <td>{rates[currency]?.Alış}</td>
              <td className={getChangeClass(rates[currency]?.Değişim)}>{rates[currency]?.Değişim}</td>
            </tr>
          ))}
          <tr>
            <td><img src={altin} alt="Gram Altın" /></td>
            <td>Gram Altın</td>
            <td>{rates['gram-altin']?.Alış}</td>
            <td className={getChangeClass(rates['gram-altin']?.Değişim)}>{rates['gram-altin']?.Değişim}</td>
          </tr>
          <tr>
            <td><img src={altin} alt="Çeyrek Altın" /></td>
            <td>Çeyrek Altın</td>
            <td>{rates['ceyrek-altin']?.Alış}</td>
            <td className={getChangeClass(rates['ceyrek-altin']?.Değişim)}>{rates['ceyrek-altin']?.Değişim}</td>
          </tr>
          <tr>
            <td><img src={altin} alt="Tam Altın" /></td>
            <td>Tam Altın</td>
            <td>{rates['tam-altin']?.Alış}</td>
            <td className={getChangeClass(rates['tam-altin']?.Değişim)}>{rates['tam-altin']?.Değişim}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CurrencyTable;
