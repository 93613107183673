import React, { useEffect, useState } from "react";
import "./left.css";

const AdsLeft = () => {
  const [ad, setAd] = useState(null);

  useEffect(() => {
    // Fetch advertisement data from the API
    fetch("https://api.sigortagundem.com/api/ads")
      .then((response) => response.json())
      .then((data) => {
        const verticalAd = data.find((ad) => ad.title.includes("Sol DİKEY")&& ad.isActive === true);
        setAd(verticalAd);
      })
      .catch((error) => {
        console.error("Error fetching advertisement data:", error);
      });
  }, []);

  const handleAdClick = async () => {
    if (ad && ad._id) {
      try {
        await fetch(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`, {
          method: "POST",
        });
      } catch (error) {
        console.error("Error incrementing hit count:", error);
      }
    }
  };

  if (!ad) {
    return null; // Or a loading spinner
  }

  return (
    <div className="vertical-fixed-left adss">
      <a
        href={ad.link}
        target="_blank"
        rel="noopener noreferrer"
        className="ad-container"
        onClick={handleAdClick}
      >
        <img src={ad.imageURL} alt={ad.title} />
      </a>
    </div>
  );
};

export default AdsLeft;
