import React, { useEffect, useState } from "react";
import axios from "axios";
import "./trendnews.css";
import qnb from "./300x250.jpg";
import ads2 from "./gazze.jpg";
import TitleBar from "../titleBar/TitleBar";

const AdContainer = ({ ad }) => {
  const adRef = React.useRef(null);

  React.useEffect(() => {
    adRef.current.innerHTML = ""; // Önceki içeriği temizle

    if (
      ad.imageURL.includes("doubleclick.net") ||
      ad.imageURL.includes("sadbundle")
    ) {
      const iframe = document.createElement("iframe");
      iframe.src = ad.imageURL;
      iframe.width = "100%";
      iframe.height = "100%";
      iframe.frameBorder = "0";
      iframe.scrolling = "no";
      iframe.style = "border: 0px; vertical-align: bottom;";
      if (ad.imageURL.includes("sadbundle")) {
        iframe.allowFullscreen = true;
      }
      adRef.current.appendChild(iframe);
    } else {
      const img = document.createElement("img");
      img.src = ad.imageURL;
      img.alt = ad.title;
      img.className = "mb-2";
      img.style =
        "margin: 0 auto; width: 100%; height: 100%; object-fit: contain;";
      adRef.current.appendChild(img);
    }
  }, [ad]);

  return (
    <a href={ad.link} target="_blank" rel="noopener noreferrer">
      <div ref={adRef} className="mb-2" style={{ margin: "0 auto" }}>
        {/* Reklam içeriği buraya dinamik olarak eklenecek */}
      </div>
    </a>
  );
};

const Trending = () => {
  const [ads, setAds] = useState([]);
  const [popularNews, setPopularNews] = useState([]);

  useEffect(() => {
    
    axios
      .get("https://api.sigortagundem.com/api/ads")
      .then((response) => {
        
        const filteredAds = response.data.filter(
          (ad) => ad.title.includes("Sidemenü Reklam") && ad.isActive === true
        );
        setAds(filteredAds);
      })
      .catch((error) => {
        console.error("Error fetching ads:", error);
      });

    
    axios
      .get("https://api.sigortagundem.com/news")
      .then((response) => {
        
        const popularNewsItems = response.data.filter(
          (news) => news.isPopularNews
        );
        setPopularNews(popularNewsItems);
      })
      .catch((error) => {
        console.error("Error fetching popular news:", error);
      });
  }, []);

  return (
    <div className="trends">
      <div className="trending">
        <div className="ads-trends">
          {ads.slice(0, 3).map((ad, index) => (
            <AdContainer key={index} ad={ad} />
          ))}
        </div>
      </div>{" "}
    </div>
  );
};

export default Trending;
