import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; 
import "./AlbumContentPage.css";
import CommentSection from "../../components/comments/CommentSection";
import axios from "axios";
import albumcover5 from "./album5/album5cover.jpg";
import SideMenu from "../../components/sideMenu/SideMenu";
import { FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp, FaFacebook, FaYoutube } from "react-icons/fa";


const AlbumContentPage5 = () => {
  const [visibleItems, setVisibleItems] = useState(16);
  const [isOpen, setIsOpen] = useState(false); 
  const [photoIndex, setPhotoIndex] = useState(0); 
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  
  const importAll = (r) => {
    return r.keys().map(r);
  };

  
  const albumImages = importAll(require.context('./album5', false, /\.(png|jpe?g|svg)$/));

  const shareUrl = window.location.href;

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnLinkedIn = () => {
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const shareOnWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const showMoreItems = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + 16);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true); 
  };

  const handleImageError = () => {
    console.error("Resim yüklenemedi!");
    setIsImageLoaded(false); 
  };

  useEffect(() => {
    setIsImageLoaded(false); 
  }, [photoIndex, isOpen]);


  return (
    <div className="album-content-page-container">
      <div className="page-link-container md:px-60 items-center flex flex-wrap text-left text-base mt-6 font-medium">
        <a href="/" className="hover:underline">
          Sigorta Gündem
        </a>
        <span class="material-symbols-outlined">
chevron_right
</span>
        <a href="/foto-galeri" className="hover:underline">
          Foto Galeri
        </a>
        <span class="material-symbols-outlined">
chevron_right
</span>
        <a href="#" className="hover:underline">
        PSM AWARDS’22 Panel: Dijital Tsunami
        </a>
      </div>

      <div className="album-content-page-header md:px-60 py-6">
        <h1 className="text-4xl font-bold mb-3">PSM AWARDS’22 Panel: Dijital Tsunami</h1>
        <img className="h-[400px] album-page-cover" src={albumcover5} alt="" />
        <div className="flex items-center text-gray-600 mt-2">
          <div>
            <p className="text-xs">
              Yorum <span class="material-symbols-outlined text-sm">
chat_bubble
</span>
              {/* • Görüntülenme <i className="fa-solid fa-eye"></i> */}
            </p>
          </div>
        </div>
      </div>

      <div className="album-content-container  md:px-60">

        <div className="share-card-container text-white mt-8">
            <div className="h-14 bg-[#101010] pl-6 flex items-center">
              <span class="material-symbols-outlined mr-4">
share
</span>
              <span className="font-bold mr-6">PAYLAŞ</span>

              <div className="share-link-container text-lg flex gap-2">
                <a onClick={shareOnFacebook} className="hover:bg-[#0867FF] hover:border-[#0867FF] cursor-pointer">
                  <FaFacebook />
                </a>
                <a onClick={shareOnTwitter} className="hover:bg-[#50ABF1] hover:border-[#50ABF1] cursor-pointer">
                  <FaTwitter />
                </a>
                <a onClick={shareOnLinkedIn} className="hover:bg-[#0274B3] hover:border-[#0274B3] cursor-pointer">
                  <FaLinkedin />
                </a>
                <a onClick={shareOnWhatsApp} className="hover:bg-[#26CA43] hover:border-[#26CA43] cursor-pointer">
                  <FaWhatsapp />
                </a>
              </div>
            </div>
      

          </div>

        <div className="album-content-left-container md:px-[30px]">

        <div className="album-content">
            <div className="category-news-grid-container">
              <div className="category-news-grid3">
                {albumImages.slice(0, visibleItems).map((image, index) => (
                  <a
                    href="#"
                    key={index}
                    className="category-news-item"
                    onClick={() => { setIsOpen(true); setPhotoIndex(index); }} 
                  >
                    <img src={image} alt={`album ${index}`} />
                  </a>
                ))}
              </div>
            </div>
            <div className="text-center">
              {visibleItems < albumImages.length && (
                <button onClick={showMoreItems} className="load-more-button category-page-load-more-button">
                  Daha Fazla Fotoğraf Yükle
                </button>
              )}
            </div>
            </div>
        

         

          
        </div>

        
      </div>

{/* Lightbox Component */}
{isOpen && (
        <Lightbox
          mainSrc={albumImages[photoIndex]}
          nextSrc={albumImages[(photoIndex + 1) % albumImages.length]}
          prevSrc={albumImages[(photoIndex + albumImages.length - 1) % albumImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + albumImages.length - 1) % albumImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % albumImages.length)}
          imageLoadErrorMessage="Resim yüklenemedi"
          onImageLoad={handleImageLoad}
          onImageLoadError={handleImageError}
        />
      )}

    </div>
  );
};

export default AlbumContentPage5;
