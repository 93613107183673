import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AdContainer from './AdMidCon'; // AdContainer bileşenini import ediyoruz
import "./adsmid.css";

const AdsMidBottom = () => {
  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get('https://api.sigortagundem.com/api/ads');
        const data = response.data;

        const filteredAds = data.filter(ad => 
          ad.title.includes("Sıcak Haberler ÜSTÜ BANNER") && ad.isActive === true
        );

        setAds(filteredAds);

        // Hit sayısını artırmak için her reklam için API çağrısı yapalım
        for (const ad of filteredAds) {
          await axios.post(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`);
        }
      } catch (error) {
        console.error("Reklamlar çekilemedi:", error);
      }
    };

    fetchAds();
  }, []);

  const handleAdClick = async (ad) => {
    if (ad && ad._id) {
      try {
        await axios.post(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`);
        window.open(ad.link, '_blank');
      } catch (error) {
        console.error("Error incrementing hit count:", error);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 5000); // Her 5 saniyede bir reklam değişir

    return () => clearInterval(interval);
  }, [ads]);

  return (
    <div className='mid-bottom-ads-container-x'>
      {ads.length > 0 && (
        <AdContainer
          ads={ads}
          currentAdIndex={currentAdIndex}
          handleAdClick={handleAdClick}
          containerClass="advertisement-x"
        />
      )}
    </div>
  );
};

export default AdsMidBottom;