import React, { useState } from 'react';
import './puanDurumu.css';

function App() {
  const lig1 = [
    { id: 1, name: 'Galatasaray', O: 34, G: 22, B: 5, M: 7, A: 79, Y: 36, AV: 43, P: 71 },
    { id: 2, name: 'Fenerbahçe', O: 34, G: 20, B: 9, M: 5, A: 75, Y: 39, AV: 36, P: 69 },
    { id: 3, name: 'Beşiktaş', O: 34, G: 19, B: 8, M: 7, A: 68, Y: 41, AV: 27, P: 65 },
    { id: 4, name: 'Trabzonspor', O: 34, G: 18, B: 9, M: 7, A: 74, Y: 45, AV: 29, P: 63 },
    { id: 5, name: 'Alanyaspor', O: 34, G: 18, B: 9, M: 7, A: 68, Y: 38, AV: 30, P: 63 },
    { id: 6, name: 'Göztepe', O: 34, G: 11, B: 11, M: 12, A: 46, Y: 48, AV: -2, P: 44 },
    { id: 7, name: 'Antalyaspor', O: 34, G: 10, B: 13, M: 11, A: 38, Y: 53, AV: -15, P: 43 },
    { id: 8, name: 'Kasımpaşa', O: 34, G: 12, B: 6, M: 16, A: 55, Y: 55, AV: 0, P: 42 },
    { id: 9, name: 'Yeni Malatyaspor', O: 34, G: 9, B: 15, M: 10, A: 42, Y: 52, AV: -10, P: 42 },
    { id: 10, name: 'Gaziantep FK', O: 34, G: 10, B: 11, M: 13, A: 43, Y: 53, AV: -10, P: 41 },
    { id: 11, name: 'Fatih Karagümrük', O: 34, G: 11, B: 8, M: 15, A: 52, Y: 58, AV: -6, P: 41 },
    { id: 12, name: 'Çaykur Rizespor', O: 34, G: 9, B: 14, M: 11, A: 42, Y: 52, AV: -10, P: 41 },
    { id: 13, name: 'Kayserispor', O: 34, G: 10, B: 10, M: 14, A: 45, Y: 60, AV: -15, P: 40 },
    { id: 14, name: 'Gençlerbirliği', O: 34, G: 9, B: 11, M: 14, A: 39, Y: 46, AV: -7, P: 38 },
    { id: 15, name: 'MKE Ankaragücü', O: 34, G: 10, B: 8, M: 16, A: 38, Y: 56, AV: -18, P: 38 },
    { id: 16, name: 'İstanbul Başakşehir', O: 34, G: 9, B: 11, M: 14, A: 41, Y: 54, AV: -13, P: 38 },
    { id: 17, name: 'Denizlispor', O: 34, G: 6, B: 12, M: 16, A: 36, Y: 57, AV: -21, P: 30 },
    { id: 18, name: 'Konyaspor', O: 34, G: 6, B: 12, M: 16, A: 35, Y: 54, AV: -19, P: 30 },
    { id: 19, name: 'BB Erzurumspor', O: 34, G: 7, B: 9, M: 18, A: 34, Y: 65, AV: -31, P: 30 },
    { id: 20, name: 'Giresunspor', O: 34, G: 6, B: 11, M: 17, A: 37, Y: 61, AV: -24, P: 29 },
  ];
  const lig2 = [
    { id: 1, name: 'Manchester City', O: 38, G: 27, B: 5, M: 6, A: 83, Y: 32, AV: 51, P: 86 },
    { id: 2, name: 'Manchester United', O: 38, G: 21, B: 11, M: 6, A: 73, Y: 44, AV: 29, P: 74 },
    { id: 3, name: 'Liverpool', O: 38, G: 20, B: 9, M: 9, A: 68, Y: 42, AV: 26, P: 69 },
    { id: 4, name: 'Chelsea', O: 38, G: 19, B: 10, M: 9, A: 58, Y: 36, AV: 22, P: 67 },
    { id: 5, name: 'Leicester City', O: 38, G: 20, B: 6, M: 12, A: 68, Y: 50, AV: 18, P: 66 },
    { id: 6, name: 'West Ham United', O: 38, G: 19, B: 8, M: 11, A: 62, Y: 47, AV: 15, P: 65 },
    { id: 7, name: 'Tottenham Hotspur', O: 38, G: 18, B: 8, M: 12, A: 68, Y: 50, AV: 18, P: 62 },
    { id: 8, name: 'Arsenal', O: 38, G: 18, B: 7, M: 13, A: 55, Y: 39, AV: 16, P: 61 },
    { id: 9, name: 'Leeds United', O: 38, G: 18, B: 5, M: 15, A: 62, Y: 54, AV: 8, P: 59 },
    { id: 10, name: 'Everton', O: 38, G: 17, B: 8, M: 13, A: 47, Y: 48, AV: -1, P: 59 },
    { id: 11, name: 'Aston Villa', O: 38, G: 16, B: 7, M: 15, A: 55, Y: 46, AV: 9, P: 55 },
    { id: 12, name: 'Newcastle United', O: 38, G: 12, B: 9, M: 17, A: 46, Y: 62, AV: -16, P: 45 },
    { id: 13, name: 'Wolverhampton Wanderers', O: 38, G: 12, B: 9, M: 17, A: 36, Y: 52, AV: -16, P: 45 },
    { id: 14, name: 'Crystal Palace', O: 38, G: 12, B: 8, M: 18, A: 41, Y: 66, AV: -25, P: 44 },
    { id: 15, name: 'Southampton', O: 38, G: 12, B: 7, M: 19, A: 47, Y: 68, AV: -21, P: 43 },
    { id: 16, name: 'Brighton & Hove Albion', O: 38, G: 9, B: 14, M: 15, A: 40, Y: 46, AV: -6, P: 41 },
    { id: 17, name: 'Burnley', O: 38, G: 10, B: 9, M: 19, A: 33, Y: 55, AV: -22, P: 39 },
    { id: 18, name: 'Fulham', O: 38, G: 5, B: 13, M: 20, A: 27, Y: 53, AV: -26, P: 28 },
    { id: 19, name: 'West Bromwich Albion', O: 38, G: 5, B: 11, M: 22, A: 35, Y: 76, AV: -41, P: 26 },
    { id: 20, name: 'Sheffield United', O: 38, G: 7, B: 2, M: 29, A: 20, Y: 63, AV: -43, P: 23 },
  ];
  const bundesliga = [
    { id: 1, name: 'Bayern Munich', O: 34, G: 27, B: 3, M: 4, A: 92, Y: 32, AV: 60, P: 84 },
    { id: 2, name: 'RB Leipzig', O: 34, G: 22, B: 7, M: 5, A: 81, Y: 32, AV: 49, P: 73 },
    { id: 3, name: 'Borussia Dortmund', O: 34, G: 20, B: 4, M: 10, A: 85, Y: 47, AV: 38, P: 64 },
    { id: 4, name: 'Bayer Leverkusen', O: 34, G: 19, B: 8, M: 7, A: 71, Y: 42, AV: 29, P: 65 },
    { id: 5, name: 'Union Berlin', O: 34, G: 17, B: 6, M: 11, A: 61, Y: 42, AV: 19, P: 57 },
    { id: 6, name: 'VfL Wolfsburg', O: 34, G: 17, B: 10, M: 7, A: 61, Y: 37, AV: 24, P: 61 },
    { id: 7, name: 'Eintracht Frankfurt', O: 34, G: 16, B: 12, M: 6, A: 69, Y: 53, AV: 16, P: 60 },
    { id: 8, name: 'VfL Bochum', O: 34, G: 12, B: 12, M: 10, A: 52, Y: 55, AV: -3, P: 48 },
    { id: 9, name: '1. FC Köln', O: 34, G: 11, B: 12, M: 11, A: 54, Y: 58, AV: -4, P: 45 },
    { id: 10, name: 'SC Freiburg', O: 34, G: 11, B: 12, M: 11, A: 48, Y: 52, AV: -4, P: 45 },
    { id: 11, name: 'TSG Hoffenheim', O: 34, G: 11, B: 10, M: 13, A: 56, Y: 56, AV: 0, P: 43 },
    { id: 12, name: 'FC Augsburg', O: 34, G: 10, B: 11, M: 13, A: 40, Y: 46, AV: -6, P: 41 },
    { id: 13, name: 'Hertha BSC', O: 34, G: 10, B: 11, M: 13, A: 45, Y: 50, AV: -5, P: 41 },
    { id: 14, name: 'Mainz 05', O: 34, G: 9, B: 9, M: 16, A: 38, Y: 51, AV: -13, P: 36 },
    { id: 15, name: 'Arminia Bielefeld', O: 34, G: 8, B: 11, M: 15, A: 32, Y: 51, AV: -19, P: 35 },
    { id: 16, name: 'Greuther Fürth', O: 34, G: 7, B: 10, M: 17, A: 40, Y: 66, AV: -26, P: 31 },
    { id: 17, name: 'VfB Stuttgart', O: 34, G: 8, B: 6, M: 20, A: 45, Y: 73, AV: -28, P: 30 },
    { id: 18, name: 'FC Schalke 04', O: 34, G: 2, B: 6, M: 26, A: 22, Y: 92, AV: -70, P: 12 }
  ];
  const laliga = [
  { id: 1, name: 'Real Madrid', O: 38, G: 28, B: 4, M: 6, A: 94, Y: 34, AV: 60, P: 88 },
  { id: 2, name: 'Barcelona', O: 38, G: 24, B: 7, M: 7, A: 85, Y: 30, AV: 55, P: 79 },
  { id: 3, name: 'Atlético Madrid', O: 38, G: 23, B: 8, M: 7, A: 70, Y: 25, AV: 45, P: 77 },
  { id: 4, name: 'Sevilla', O: 38, G: 23, B: 5, M: 10, A: 60, Y: 28, AV: 32, P: 74 },
  { id: 5, name: 'Real Sociedad', O: 38, G: 18, B: 11, M: 9, A: 53, Y: 33, AV: 20, P: 65 },
  { id: 6, name: 'Real Betis', O: 38, G: 18, B: 10, M: 10, A: 54, Y: 38, AV: 16, P: 64 },
  { id: 7, name: 'Villarreal', O: 38, G: 16, B: 13, M: 9, A: 60, Y: 44, AV: 16, P: 61 },
  { id: 8, name: 'Celta Vigo', O: 38, G: 14, B: 11, M: 13, A: 55, Y: 57, AV: -2, P: 53 },
  { id: 9, name: 'Athletic Bilbao', O: 38, G: 14, B: 11, M: 13, A: 46, Y: 40, AV: 6, P: 53 },
  { id: 10, name: 'Granada', O: 38, G: 13, B: 7, M: 18, A: 47, Y: 63, AV: -16, P: 46 },
  { id: 11, name: 'Osasuna', O: 38, G: 12, B: 11, M: 15, A: 34, Y: 46, AV: -12, P: 47 },
  { id: 12, name: 'Rayo Vallecano', O: 38, G: 12, B: 7, M: 19, A: 37, Y: 53, AV: -16, P: 43 },
  { id: 13, name: 'Elche', O: 38, G: 10, B: 12, M: 16, A: 36, Y: 49, AV: -13, P: 42 },
  { id: 14, name: 'Alavés', O: 38, G: 9, B: 12, M: 17, A: 38, Y: 56, AV: -18, P: 39 },
  { id: 15, name: 'Espanyol', O: 38, G: 9, B: 11, M: 18, A: 38, Y: 58, AV: -20, P: 38 },
  { id: 16, name: 'Mallorca', O: 38, G: 10, B: 5, M: 23, A: 38, Y: 70, AV: -32, P: 35 },
  { id: 17, name: 'Getafe', O: 38, G: 8, B: 10, M: 20, A: 31, Y: 53, AV: -22, P: 34 },
  { id: 18, name: 'Cadiz', O: 38, G: 8, B: 8, M: 22, A: 28, Y: 59, AV: -31, P: 32 },
  { id: 19, name: 'Levante', O: 38, G: 7, B: 10, M: 21, A: 45, Y: 69, AV: -24, P: 31 },
  { id: 20, name: 'Valladolid', O: 38, G: 5, B: 16, M: 17, A: 34, Y: 59, AV: -25, P: 31 }
  ];
  const tff1Lig = [
  { id: 1, name: 'Adana Demirspor', O: 34, G: 24, B: 6, M: 4, A: 64, Y: 30, AV: 34, P: 78 },
  { id: 2, name: 'Samsunspor', O: 34, G: 23, B: 6, M: 5, A: 69, Y: 30, AV: 39, P: 75 },
  { id: 3, name: 'Giresunspor', O: 34, G: 20, B: 8, M: 6, A: 68, Y: 36, AV: 32, P: 68 },
  { id: 4, name: 'Altay', O: 34, G: 19, B: 10, M: 5, A: 55, Y: 28, AV: 27, P: 67 },
  { id: 5, name: 'Ümraniyespor', O: 34, G: 18, B: 8, M: 8, A: 55, Y: 37, AV: 18, P: 62 },
  { id: 6, name: 'Bandırmaspor', O: 34, G: 15, B: 11, M: 8, A: 48, Y: 42, AV: 6, P: 56 },
  { id: 7, name: 'Menemenspor', O: 34, G: 13, B: 13, M: 8, A: 42, Y: 37, AV: 5, P: 52 },
  { id: 8, name: 'Boluspor', O: 34, G: 13, B: 10, M: 11, A: 43, Y: 36, AV: 7, P: 49 },
  { id: 9, name: 'Balıkesirspor', O: 34, G: 12, B: 9, M: 13, A: 42, Y: 43, AV: -1, P: 45 },
  { id: 10, name: 'Ankaraspor', O: 34, G: 12, B: 9, M: 13, A: 39, Y: 45, AV: -6, P: 45 },
  { id: 11, name: 'Eskişehirspor', O: 34, G: 10, B: 14, M: 10, A: 43, Y: 49, AV: -6, P: 44 },
  { id: 12, name: 'Akhisarspor', O: 34, G: 11, B: 11, M: 12, A: 38, Y: 42, AV: -4, P: 44 },
  { id: 13, name: 'İstanbulspor', O: 34, G: 11, B: 11, M: 12, A: 35, Y: 37, AV: -2, P: 44 },
  { id: 14, name: 'Hatayspor', O: 34, G: 9, B: 14, M: 11, A: 38, Y: 44, AV: -6, P: 41 },
  { id: 15, name: 'Adanaspor', O: 34, G: 8, B: 14, M: 12, A: 30, Y: 38, AV: -8, P: 38 },
  { id: 16, name: 'Aydınspor 1923', O: 34, G: 8, B: 12, M: 14, A: 32, Y: 44, AV: -12, P: 36 },
  { id: 17, name: 'Sakaryaspor', O: 34, G: 7, B: 14, M: 13, A: 34, Y: 44, AV: -10, P: 35 },
  { id: 18, name: 'Kocaelispor', O: 34, G: 7, B: 12, M: 15, A: 28, Y: 44, AV: -16, P: 33 },
  { id: 19, name: 'Karagümrük', O: 34, G: 7, B: 11, M: 16, A: 35, Y: 50, AV: -15, P: 32 },
  { id: 20, name: 'Göztepe', O: 34, G: 5, B: 14, M: 15, A: 25, Y: 50, AV: -25, P: 29 }
  ];


  // State tanımlama
  const [teams, setTeams] = useState(lig1); // Başlangıçta lig1'i göster

  // Buton 1'e tıklandığında
  const handleFirstButtonClick = () => {
    setTeams(lig1); // lig1 verilerini göster
    setActiveButton('Süper Lig'); // Aktif butonu güncelle
  };

  // Buton 2'ye tıklandığında
  const handleSecondButtonClick = () => {
    setTeams(tff1Lig); // lig2 verilerini göster
    setActiveButton('tff1Lig'); // Aktif butonu güncelle
  };
  // Buton 3'ye tıklandığında
  const handleThirdButtonClick = () => {
    setTeams(lig2); // lig3 verilerini göster
    setActiveButton('Premier League'); // Aktif butonu güncelle
  };
  // Buton 4'ye tıklandığında
  const handleFourthButtonClick = () => {
    setTeams(laliga); // lig2 verilerini göster
    setActiveButton('laliga'); // Aktif butonu güncelle
  };
  // Buton 5'ye tıklandığında
  const handleFivedButtonClick = () => {
    setTeams(bundesliga); // lig2 verilerini göster
    setActiveButton('bundesliga'); // Aktif butonu güncelle
  };

  // tıklandığında mavi aktif olması için
  const [activeButton, setActiveButton] = useState('Süper Lig');

  // const handleButtonClick = (league) => {
  //   setActiveButton(league);
  //   if (league === 'Süper Lig') {
  //     setTeams(lig1);
  //   } else if (league === 'tff1Lig') {
  //     setTeams(tff1Lig);
  //   } else if (league === 'Premier League') {
  //     setTeams(lig2);
  //   } else if (league === 'laliga') {
  //     setTeams(laliga);
  //   } else if (league === 'bundesliga') {
  //     setTeams(bundesliga);
  //   }
  // };

  return (
    <div className="ptablo">
      <div className="container  p-0 ">
        <div className="row p-0 m-0 border btnbaslik">
          <div className="col-md-12 text-left">
            <button
              className={`btn border rounded-0 ${activeButton === 'Süper Lig' ? 'btn-primary' : 'btn-ligth'}`}
              onClick={handleFirstButtonClick}
            >
              Süper Lig
            </button>
            <button
              className={`btn border rounded-0 ${activeButton === 'tff1Lig' ? 'btn-primary' : 'btn-ligth'}`}
              onClick={handleSecondButtonClick}
            >
              TFF 1.Lig
            </button>
            <button
              className={`btn border rounded-0 ${activeButton === 'Premier League' ? 'btn-primary' : 'btn-ligth'}`}
              onClick={handleThirdButtonClick}
            >
              Premier League
            </button>
                        <button
              className={`btn border rounded-0 ${activeButton === 'laliga' ? 'btn-primary' : 'btn-ligth'}`}
              onClick={handleFourthButtonClick}
            >
              LALIGA
            </button>
            <button
              className={`btn border rounded-0 ${activeButton === 'bundesliga' ? 'btn-primary' : 'btn-ligth'}`}
              onClick={handleFivedButtonClick}
            >
              Bundesliga
            </button>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="th-style">#</th>
                  <th className="text-left th-style">TAKIM</th>
                  <th className="text-center th-style">O</th>
                  <th className="text-center th-style">G</th>
                  <th className="text-center th-style">B</th>
                  <th className="text-center th-style">M</th>
                  <th className="text-center th-style">A</th>
                  <th className="text-center th-style">Y</th>
                  <th className="text-center th-style">AV</th>
                  <th className="text-center th-style">P</th>
                  <th className="text-center th-style">SON 5 KARŞILAŞMA</th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team) => (
                  <tr key={team.id} className="hover-effect">
                    <th scope="row">{team.id}</th>
                    <td className="">{team.name}</td>
                    <td className="text-center">{team.O}</td>
                    <td className="text-center">{team.G}</td>
                    <td className="text-center">{team.B}</td>
                    <td className="text-center">{team.M}</td>
                    <td className="text-center">{team.A}</td>
                    <td className="text-center">{team.Y}</td>
                    <td className="text-center">{team.AV}</td>
                    <td className="text-center">{team.P}</td>
                    <td className="text-center">Son 5 karşılaşma bilgisi</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
