import React, { useState, useEffect, useRef } from 'react';
import "./adsmid.css";
import mbad2 from "./midbtmad2.jpeg";

const AdsMidBottom2 = () => {
  const [ads, setAds] = useState([]);
  const adRef = useRef(null);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch('https://api.sigortagundem.com/api/ads');
        const data = await response.json();
        
        const filteredAds = data.filter(ad => 
          ad.title.includes("Trendsler ÜSTÜ KARE Banner") && ad.isActive === true
        );

        setAds(filteredAds);

        for (const ad of filteredAds) {
          await fetch(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`, {
            method: 'POST',
          });
        }
      } catch (error) {
        console.error("Reklamlar çekilemedi:", error);
      }
    };

    fetchAds();
  }, []);

  useEffect(() => {
    if (ads.length > 0 && adRef.current) {
      adRef.current.innerHTML = ''; // Clear previous content

      ads.forEach((ad) => {
        const adContainer = document.createElement('div');
        adContainer.className = 'ad-item';

        if (ad.imageURL.includes('doubleclick.net') || ad.imageURL.includes('sadbundle')) {
          const iframe = document.createElement('iframe');
          iframe.src = ad.imageURL;
          iframe.width = '300';
          iframe.height = '300';
          iframe.frameBorder = '0';
          iframe.scrolling = 'no';
          iframe.style = 'border: 0px; vertical-align: bottom;';
          if (ad.imageURL.includes('sadbundle')) {
            iframe.allowFullscreen = true;
          }
          adContainer.appendChild(iframe);
        } else {
          const link = document.createElement('a');
          link.href = ad.link;
          link.target = "_blank";
          link.rel = "noopener noreferrer";

          const img = document.createElement('img');
          img.src = ad.imageURL || mbad2;
          img.alt = ad.title || "Advertisement";
          img.style = 'width: 300px; height: 300px; object-fit: cover;';

          link.appendChild(img);
          adContainer.appendChild(link);
        }

        adRef.current.appendChild(adContainer);
      });
    }
  }, [ads]);

  return (
    <div className='mid-bottom-ads-container2-web' ref={adRef}>
      {/* Ad content will be dynamically inserted here */}
    </div>
  );
};

export default AdsMidBottom2;