import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import "./categoryPage.css";
import SideMenu from "../../components/sideMenu/SideMenu";
import MostRead from './MostRead';
import MostCommented from './MostCommented';
import TitleBar from '../../components/titleBar/TitleBar';

const API_BASE_URL = 'https://api.sigortagundem.com/news';

const BackstageCategory = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [visibleItems, setVisibleItems] = useState(18);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNewsItems = async () => {
            try {
                const response = await axios.get(API_BASE_URL);
                const data = response.data;

                if (Array.isArray(data)) {
                    const filteredNews = data.filter(item => item.categories.includes('Sigorta') && !item.isDraft);
                    setNewsItems(filteredNews);
                } else if (data.news && Array.isArray(data.news)) {
                    const filteredNews = data.news.filter(item => item.categories.includes('Sigorta') && !item.isDraft);
                    setNewsItems(filteredNews);
                } else {
                    console.error('Beklenen dizi formatında veri alınamadı:', data);
                }
            } catch (error) {
                console.error("Haberler çekilirken bir hata oluştu:", error);
            }
        };
        
        fetchNewsItems();
    }, []);

    const showMoreItems = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 9);
    };

    const handleNewsClick = async (newsId, slug, event) => {
        event.preventDefault();
        try {
            // Increment view count
            await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);

            // Navigate to news article
            navigate(`/news/${slug}`);
        } catch (error) {
            console.error("Error incrementing view count:", error);
            // Navigate to news article even if there's an error
            navigate(`/news/${slug}`);
        }
    };

    return (
        <div className='category-page-container'>
            <div className='category-page-top-container md:grid grid-cols-11 md:px-60'>
                <div className='category-page-top-left-container col-span-7 md:pr-[30px]'>
                <div className="mb-4 md:hidden"><TitleBar title="Sigorta" /></div>
                    <div className="category-news-grid-container">
                        <div className="category-news-grid">
                            {newsItems.slice(0, visibleItems).map((item) => (
                                <div
                                    key={item._id}
                                    className="category-news-item cursor-pointer"
                                    onClick={(e) => handleNewsClick(item._id, item.slug, e)}
                                >
                                    <img src={item.image} alt={item.title} />
                                    <div className="category-news-content">
                                        <h3 className="title">{item.title}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='text-center'>
                        {visibleItems < newsItems.length && (
                            <button onClick={showMoreItems} className="load-more-button category-page-load-more-button">
                                Daha Fazla Haber Yükle
                            </button>
                        )}
                    </div>
                    <div className='lg:grid grid-cols-2 gap-4 mt-4'>
                        {/* <MostRead />
                        <MostCommented /> */}
                    </div>
                </div>
                <div className='category-page-top-right-container col-span-4 md:pl-[30px]'>
                    <SideMenu />
                </div>
            </div>
        </div>
    )
}

export default BackstageCategory;