import React, { useEffect, useRef } from 'react';

const AdContainer = ({ ads, currentAdIndex, handleAdClick }) => {
  const adRef = useRef(null);

  useEffect(() => {
    if (ads.length > 0) {
      const currentAd = ads[currentAdIndex];
      adRef.current.innerHTML = ''; // Önceki içeriği temizle

      if (currentAd.imageURL.includes('doubleclick.net')) {
        const iframe = document.createElement('iframe');
        iframe.src = currentAd.imageURL;
        iframe.width = '728';
        iframe.height = '90';
        iframe.frameBorder = '0';
        iframe.scrolling = 'no';
        iframe.style = 'border: 0px; vertical-align: bottom;';
        adRef.current.appendChild(iframe);
      } else if (currentAd.imageURL.includes('sadbundle')) {
        const iframe = document.createElement('iframe');
        iframe.src = currentAd.imageURL;
        iframe.width = '728';
        iframe.height = '90';
        iframe.frameBorder = '0';
        iframe.scrolling = 'no';
        iframe.allowFullscreen = true;
        adRef.current.appendChild(iframe);
      } else {
        const img = document.createElement('img');
        img.src = currentAd.imageURL;
        img.alt = currentAd.title;
        img.style = 'cursor: pointer; width: 100%; height: 100%;';
        img.addEventListener('click', () => handleAdClick(currentAd));
        adRef.current.appendChild(img);
      }
    }
  }, [ads, currentAdIndex, handleAdClick]);

  return (
    <div className="advertisement" style={{ width: "728px", height: "90px" }} ref={adRef}>
      {/* Reklam içeriği buraya dinamik olarak eklenecek */}
    </div>
  );
};

export default AdContainer;