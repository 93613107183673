import React from "react";
import "./kunyePage.css";
import deneme from "./../../assets/sigorta-gundem-logo.png";
import deneme2 from "./../../assets/mini-logo2.png";
import {
  FaPhone,
  FaEnvelope,
  FaUser,
  FaMapMarker,
  FaMobile,
} from "react-icons/fa";

const KunyePage = () => {
  const kayhanBey = "Kozturk@psmmag.com";
  const barisBey = "bbekar@akilliyasamdergisi.com";
  const AbdullahBey = "acetin@psmmag.com";
  const esinHanim = "esin.gedik@gmail.com";
  const berrinHanim = "buyanik@akilliyasamdergisi.com";
  const tulinHanim = "tcakmak@psmmag.com";
  const nakiBey = "naki.demircivi@ddemlaw.com";

  return (
    <div className="kny">
      <div className="container member d-flex flex-column">
      <h4 className="knyTitle h4" >KÜNYE</h4>
        <div className="row  w-100 h-100">
          <div className="col-1 containerBox mb-4 p-0">
            <div className="row">
              <div className="col-6 w-100">
                <div className="row p-1">
                <div className="col-5 h-full d-flex justify-content-center ">
                    <img src={deneme} className="img-fluid w-30 h-40 p-3" alt="" ></img>
                  </div>
                  <div className="col-6 contentBox ">
                    <h6 className="h6 kunyeTitle flex">
                      Erişim Medya Radyo Televizyon ve Dergi Yayıncılık Adına
                      İmtiyaz Sahibi
                    </h6>
                    <p className="kunyeContent p-2 text-left">
                      <p className="kunyeName flex">
                        <FaUser className="flex mr-6 mt-1 icon" />
                        <span>Kayhan Öztürk</span>
                        <br />
                      </p>
                      <a href={`mailto:${kayhanBey}`} className="flex">
                        <FaEnvelope className="flex mr-6 mt-1 icon" />{" "}
                        Kozturk@psmmag.com
                      </a>
                      <p className="kunyePhone ">
                        <span className="flex">
                          <FaPhone className="flex mt-1 mr-6 icon" />
                          (+90)-523-000-4444
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-6 noneShow"></div> */}
            </div>
          </div>
          <div className="col-1 containerBox mb-4 p-0">
            <div className="row ">
              <div className="col-6 w-100">
                <div className="row p-1">
                  <div className="col-5 h-full d-flex justify-content-center ">
                    <img src={deneme} className="img-fluid w-30 h-40 p-3" alt="" />
                  </div>
                  <div className="col-6 contentBox">
                    <h6 className="h6 kunyeTitle flex justify-content-center">
                      Yayın Kooordinatörü
                    </h6>
                    <p className="kunyeContent p-2 text-left">
                      <p className="kunyeName flex">
                        <FaUser className="flex mr-6 mt-1" />
                        <span>Barış Bekar</span>
                        <br />
                      </p>
                      <a href={`mailto:${barisBey}`} className="flex">
                      <FaEnvelope className="flex mr-6 mt-1 icon"/>{" "}
                      bbekar@akilliyasamdergisi.com
                      </a>
                      <p className="kunyePhone ">
                        <span className="flex">
                          <FaPhone className="flex mt-1 mr-6" />
                          (+90)-523-000-4444
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </div>{" "}
              {/* <div className="col-6"></div> */}
            </div>
          </div>
          <div className="col-1 containerBox mb-4 p-0">
            <div className="row">
              <div className="col-6 w-100">
                <div className="row p-1">
                  <div className="col-5 h-full d-flex justify-content-center ">
                    <img src={deneme} className="img-fluid w-30 h-40 p-3" alt="" />
                  </div>
                  <div className="col-6 contentBox">
                    <h6 className="h6 kunyeTitle flex justify-content-center">
                      Yayın Danışmanı
                    </h6>
                    <p className="kunyeContent p-2 text-left">
                      <p className="kunyeName flex">
                        <FaUser className="flex mr-6 mt-1" />
                        <span>Abdullah Çetin</span>
                        <br />
                      </p>
                      <a href={`mailto:${AbdullahBey}`} className="flex">
                        <FaEnvelope className="flex mr-6 mt-1 icon" />
                        acetin@psmmag.com
                      </a>
                      <p className="kunyePhone ">
                        <span className="flex">
                          <FaPhone className="flex mt-1 mr-6" />
                          (+90)-523-000-4444
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </div>{" "}
              {/* <div className="col-6"></div> */}
            </div>
          </div>
          <div className="col-1 containerBox mb-4 p-0">
            <div className="row">
              <div className="col-6 w-100">
                <div className="row p-1">
                  <div className="col-5 h-full d-flex justify-content-center ">
                    <img
                      src={deneme}
                      className="img-fluid w-30 h-40 mt-3 p-3"
                      alt=""
                    />
                  </div>
                  <div className="col-6 contentBox">
                    <h6 className="h6 kunyeTitle flex justify-content-center">
                      Editörler
                    </h6>
                    <p className="kunyeContent p-2 text-left">
                      <p className="kunyeName flex">
                        <FaUser className="flex mr-6 mt-1" />
                        <span>Esin Gedik</span>
                        <br />
                      </p>
                      <a href={`mailto:${esinHanim}`} className="flex">
                        <FaEnvelope className="flex mr-6 mt-1" />
                        esin.gedik@gmail.com
                      </a>
                      <p className="kunyePhone ">
                        <span className="flex">
                          <FaPhone className="flex mt-1 mr-6" />
                          (+90)-523-000-4444
                        </span>
                      </p>
                      <hr className="w-full" />
                      <p className="kunyeName flex">
                        <FaUser className="flex mr-6 mt-1" />
                        <span>Berrin Vildan Uyanık Bekar</span>
                        <br />
                      </p>
                      <a href={`mailto:${berrinHanim}`} className="flex">
                        <FaEnvelope className="flex mr-6 mt-1" />
                        buyanik@akilliyasamdergisi.com
                      </a>
                      <p className="kunyePhone ">
                        <span className="flex">
                          <FaPhone className="flex mt-1 mr-6" />
                          (+90)-523-000-4444
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </div>{" "}
              {/* <div className="col-6"></div> */}
            </div>
          </div>
          <div className="col-1 containerBox mb-4 p-0">
            <div className="row">
              <div className="col-6 w-100">
                <div className="row p-1">
                  <div className="col-5 h-full d-flex justify-content-center ">
                    <img src={deneme} className="img-fluid w-30 h-40 p-3" alt="" />
                  </div>
                  <div className="col-6 contentBox">
                    <h6 className="h6 kunyeTitle flex justify-content-center">
                      Marka ve Etkinlik Yönetimi
                    </h6>
                    <p className="kunyeContent p-2 text-left">
                      <p className="kunyeName flex">
                        <FaUser className="flex mr-6 mt-1" />
                        <span>Tülin Çakmak</span>
                        <br />
                      </p>
                      <a href={`mailto:${tulinHanim}`} className="flex">
                        <FaEnvelope className="flex mr-6 mt-1" />
                        tcakmak@psmmag.com
                      </a>
                      <p className="kunyePhone ">
                        <span className="flex">
                          <FaPhone className="flex mt-1 mr-6" />
                          (+90)-523-000-4444
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-6"></div> */}
            </div>
          </div>
          <div className="col-1 containerBox mb-4 p-0">
            <div className="row">
              <div className="col-6 w-100">
                <div className="row p-1">
                  <div className="col-5 h-full d-flex justify-content-center ">
                    <img src={deneme} className="img-fluid w-30 h-40 p-3" alt="" />
                  </div>
                  <div className="col-6 contentBox">
                    <h6 className="h6 kunyeTitle flex justify-content-center">
                      Reklam Rezervasyon
                    </h6>
                    <p className="kunyeContent p-2 text-left">
                      <p className="kunyeName flex">
                        <FaUser className="flex mr-6 mt-1" />
                        <span>Barış Bekar</span>
                        <br />
                      </p>
                      <a href={`mailto:${barisBey}`} className="flex">
                        <FaEnvelope className="flex mr-6 mt-1" />{" "}
                        bbekar@akilliyasamdergisi.com{" "}
                      </a>
                      <p className="kunyePhone ">
                        <span className="flex">
                          <FaPhone className="flex mt-1 mr-6" />
                          (+90)-523-000-4444
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </div>{" "}
              {/* <div className="col-6"></div> */}
            </div>
          </div>
          <div className="col-1 containerBox mb-4 p-0">
            <div className="row">
              <div className="col-6 w-100">
                <div className="row p-1">
                  <div className="col-5 h-full d-flex justify-content-center ">
                    <img src={deneme} className="img-fluid w-30 h-40 p-3" alt="" />
                  </div>
                  <div className="col-6 contentBox">
                    <h6 className="h6 kunyeTitle flex justify-content-center">
                      Hukuk Müşaviri
                    </h6>
                    <p className="kunyeContent p-2 text-left">
                      <p className="kunyeName flex">
                        <FaUser className="flex mr-6 mt-1" />
                        <span>Av. H. Naki Demirçivi</span>
                        <br />
                      </p>
                      <a href={`mailto:${nakiBey}`} className="flex">
                        <FaEnvelope className="flex mr-6 mt-1" />{" "}
                        naki.demircivi@ddemlaw.com{" "}
                      </a>
                      <p className="kunyePhone ">
                        <span className="flex">
                          <FaPhone className="flex mt-1 mr-6" />
                          (+90)-523-000-4444
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </div>{" "}
              {/* <div className="col-6"></div> */}
            </div>
          </div>
          <div className="col-1 containerBox mb-4 p-0">
            <div className="row">
              <div className="col-5 h-full d-flex justify-content-center mapBox mt-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.783574349977!2d29.028493511500518!3d40.98621972066344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab927e9d8c52b%3A0xa510d33cae6c139e!2sEri%C5%9Fim%20Medya!5e0!3m2!1str!2str!4v1720163184140!5m2!1str!2str"
                  className="img-fluid"
                  width="250"
                  height="150"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>{" "}
              </div>
              <div className="col-6 contentBox">
                <h3 className="h6">ADRES</h3>
                <p className="kunyeContent p-2 text-left">
                  <span className="flex ">
                    <FaMapMarker
                      className="flex  mr-6 mapIcon"
                    />
                    ERİŞİM MEDYA RADYO TELEVİZYON VE DERGİ YAYINCILIK ANONİM
                    ŞİRKETİ Osmanağa Mah. Hasırcıbaşı Cad. Hasırcıbaşı Apt.
                    No:15/3 Kadıköy/İstanbul <br />
                  </span>
                  <span className="flex">
                    <FaPhone className="flex mt-1 mr-6" />
                    (+90)-216-550-10 61 / 62
                  </span>
                  <span className="flex">
                    <FaMobile className="flex mt-1 mr-6" />
                    (+90)-532-462- 76 87
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KunyePage;
